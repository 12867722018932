<script>
export default {
    
}
</script>

<template>
    <footer class="footer">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-6">{{ new Date().getFullYear()}} © Vrouven Eredivisie.</div>
                <div class="col-sm-6">
                    <div class="text-sm-end d-none d-sm-block">
                        Powered by
                        <a href="https://www.azerion.com/" target="_blank" class="text-reset">Azerion</a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>